import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "./BasicDetails.css"
import OvalLoader from '../../Loader/OvalLoader'
import { updateUserProfile } from '../../actions/userAction'


function BasicDetails() {
  const { loading, userDetails } = useSelector((state) => state.userDetails)
  const [disable, setDisable] = useState(true)
  const [details, setDetails] = useState({})
const dispatch = useDispatch()


  const handleEdit = () => {
    setDisable(!disable)
  }

  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value })
  }

  const handleUpdate=()=>{
  dispatch(updateUserProfile(details))
  setDisable(true)
  }
  return (
    <>
      {loading && <div className='basicDetailsContainer'><OvalLoader height={50} width={50} /></div>}
      {!loading && <div className='basicDetailsContainer'>
        <div className='col1'>
          <label className='details-label'>Name </label>
          <div className='nameInput'>
            <input className='details-input' name="firstName" onChange={onChange} defaultValue={userDetails.firstName} disabled={disable} />
            <input className='details-input' name="secondName" onChange={onChange} defaultValue={userDetails.secondName} disabled={disable} />
          </div>

          <label className='details-label'>Email </label>
          <input className='details-input' name="emailID" onChange={onChange} defaultValue={userDetails.emailID} disabled={disable} />
        </div>

        <div className='col2'>
          <label className='details-label' >Mobile Number </label>
          <input className='details-input' name="" onChange={onChange} defaultValue="NA" disabled={disable} />

          <label className='details-label'>Company Name </label>
          <input className='details-input' name="company" onChange={onChange} defaultValue={userDetails.company} disabled={disable} />
        </div>
      </div>}
      {!loading && disable&&<button className='detailsEditBtn' onClick={handleEdit}>Edit</button>}
      {!loading && !disable&&<button className='detailsEditBtn' onClick={handleUpdate}>Update</button>}
    </>
  )
}

export default BasicDetails