import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Header from '../../Component/Header/Header'
import ProductCard from '../../Component/ProductCard/ProductCard'
import Footer from "../../Component/Footer/Footer"
import SearchLoader from "../../Loader/SearchLoader"
import "./Products.css"
import FilterBar from '../../Component/FilterSideBar/FilterBar'

function Products() {
    const { loading, products } = useSelector((state) => state.product)
    let firstPageResult = 0
    let lastPageResult = 0
    let totalResult = 0

    if (products.data != undefined) {
        firstPageResult = (products.data.pageSize * products.data.pageNumber)
        lastPageResult = (firstPageResult + products.data.pageSize)
        totalResult = products.data.count
    }

    const scrollToTop = () => { window.scrollTo(0, 0) }

    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <>
            <Header />
            <div className='pageContainer'>
                <FilterBar />
                {loading && <div style={{ width: "98vw" }} className='productsContainer'><SearchLoader /></div>}

                {!loading && <div className='productsContainer' >
                    <div className='result'>

                        {totalResult < products.data.pageSize ?
                            <p className='text '>
                                {`Showing 1 - ${totalResult} Of ${totalResult} Results`}
                            </p> :
                            <p className='text '>
                                {`Showing ${firstPageResult}-${lastPageResult} result of total ${totalResult}  results`}
                            </p>}
                        <div>
                            <label htmlFor="sortOptions" style={{ fontWeight : "600" , color : "#982E53"}}>Sort By &#160;</label>
                            <select
                                id='sortOptions'
                            // value={selectedOption}
                            // onChange={handleSortChange}
                            >
                                <option className='sortOption' value="default">Relevance</option>
                                <option className='sortOption' value="priceAsc">Price: Low to High</option>
                                <option className='sortOption' value="priceDesc">Price: High to Low</option>
                                <option className='sortOption' value="nameDesc">Newest First</option>
                            </select>
                        </div>
                    </div>

                    <div className='products'>
                        {products.data.searchedResult.length > 0 ?
                            products.data.searchedResult.map((e) =>
                                <ProductCard coverImagePath={e.productImg[0]} productName={e.productName} productPrice={e.productListingPrice} productMRP={e.productMRP} productID={e._id}  />)
                            : <div>No Products</div>}
                    </div> </div>}
            </div>
            <div className='pageFooter'>  <Footer /> </div>
        </>
    )
}

export default Products