export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_RESET = "USER_PROFILE_RESET";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const USER_PRODUCT_REQUEST = "USER_PRODUCT_REQUEST";
export const USER_PRODUCT_SUCCESS = "USER_PRODUCT_SUCCESS";
export const USER_PRODUCT_RESET = "USER_PRODUCT_RESET";
export const USER_PRODUCT_FAIL = "USER_PRODUCT_FAIL";

export const USER_ORDERS_REQUEST = "USER_ORDERS_REQUEST";
export const USER_ORDERS_SUCCESS = "USER_ORDERS_SUCCESS";
export const USER_ORDERS_FAIL = "USER_ORDERS_FAIL";

export const USER_ADDRESS_REQUEST = "USER_ADDRESS_REQUEST";
export const USER_ADDRESS_SUCCESS = "USER_ADDRESS_SUCCESS";
export const USER_ADDRESS_RESET = "USER_ADDRESS_RESET";
export const USER_ADDRESS_FAIL = "USER_ADDRESS_FAIL";

export const UPDATE_ADDRESS_REQUEST = "UPDATE_ADDRESS_REQUEST";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_RESET = "UPDATE_ADDRESS_RESET";
export const UPDATE_ADDRESS_FAIL = "UPDATE_ADDRESS_FAIL";

export const USER_ADDRESSDETAIL_REQUEST = "USER_ADDRESSDETAIL_REQUEST";
export const USER_ADDRESSDETAIL_SUCCESS = "USER_ADDRESSDETAIL_SUCCESS";
export const USER_ADDRESSDETAIL_RESET = "USER_ADDRESSDETAIL_RESET";
export const USER_ADDRESSDETAIL_FAIL = "USER_ADDRESSDETAIL_FAIL";

