import React from 'react'
import "./CheckoutItemCard.css"
import axios from "axios";

function CheckoutItemCard(props) {

  // const hostName = "http://localhost:1000"
  const hostName = "https://ecommerce-api.leetwolf.in"
  // console.log(props);


  return (
    <div className='orderSummaryContainer'>
     <p className='checkoutHeading'>Order Summary</p>
      <div className='checkoutItems'>
        <img style={{ padding: "0.5rem" }} src={`${hostName}/${props.imagePath}`} alt="Product Image"></img>

        <div className='itemDetails'>
          <p className="fw-bold" id="cartProductName">{props.productName}</p>
          <p><span className='fw-bold' >Color : </span> <span id="Color">{props.color}</span></p>
          {/* <div>
            <i class="uil uil-minus-circle sub" onClick={subQuantity}></i>
            <input id="Quantity" type="number" value={props.quantity} readOnly />
            <i class="uil uil-plus-circle add" onClick={addQuantity}></i>
            <span class="cart-btn">SAVE FOR LATER</span>
            <span class="cart-btn">DELETE</span>
          </div> */}
        </div>

        <div className='priceContainer'>
          {/* <p id="MRP"><i class="uil uil-dollar-alt"></i>{props.MRP}</p> */}
          <p id="ListingPrice">Price : <i class="uil uil-dollar-alt"></i>{props.listingPrice}</p>
        </div>

      </div>


    </div>
  )
}

export default CheckoutItemCard
