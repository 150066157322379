import React from 'react';
import { Link } from 'react-router-dom'
import "./Register&Auth.css";

function Register() {
  return (
    <>
      <div className="image_box"></div>

      <form className='signupForm'>
        <h2 className='text-center'>Sign up</h2>
        <p className='text-center'>Please enter your details</p>
        <label className="form-label">First Name</label>
        <input className="form-input" placeholder='First Name' />
        <label className="form-label" style={{ marginTop: "15px " }}>Last Name</label>
        <input className="form-input" placeholder='Last Name' />
        <label className="form-label" style={{ marginTop: "15px " }}>Password</label>
        <input className="form-input" placeholder='Password' />
        <label className="form-label" style={{ marginTop: "15px " }} >Company</label>
        <input className="form-input" placeholder='Company' />
        <label className="form-label" style={{ marginTop: "15px " }} >Email</label>
        <input className="form-input" placeholder='Email' />
        <label className="form-label" style={{ marginTop: "15px " }} >Confirm password</label>
        <input className="form-input" placeholder='Confirm password' />
        <div className='mt-3'>
          <button className="sub_btn">Sign up</button>
        </div>
        <div className='accountSwitch'>
          <label className="mt-1"> Already have an account?</label>
          <Link className="fw-bold" to="/login" >  LOGIN</Link>
        </div>
      </form>
    </>
  )
}

export default Register