import {
    ALL_PRODUCTS_FAIL, ALL_PRODUCTS_REQUEST, ALL_PRODUCTS_SUCCESS, PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL, CLEAR_ERRORS, NEW_PRODUCT_REQUEST, NEW_PRODUCT_SUCCESS, NEW_PRODUCT_FAIL, NEW_PRODUCT_RESET, UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAIL, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAIL,
    SLIDER_PRODUCTS_REQUEST, SLIDER_PRODUCTS_SUCCESS, SLIDER_PRODUCTS_FAIL, PRODUCTBYPRICE_DETAILS_FAIL, PRODUCTBYPRICE_DETAILS_REQUEST, PRODUCTBYPRICE_DETAILS_SUCCESS,
    CATEGORY_DETAILS_REQUEST , CATEGORY_DETAILS_SUCCESS ,CATEGORY_DETAILS_FAIL 
} from "../constants/ProductConstants";

const initialState = { loading: false, error: null }

// New Product Reducer
export const newProductReducer = (state = { ...initialState, product: {} }, { type, payload }) => {
    switch (type) {
        case NEW_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case NEW_PRODUCT_SUCCESS:
            return {
                loading: false,
                success: payload.success,
                product: payload.product,
            };
        case NEW_PRODUCT_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case NEW_PRODUCT_RESET:
            return {
                ...state,
                success: false,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

export const productsReducer = (state = { ...initialState, products: { searchedResult: [], filters: { brands: [], colors: [], sizes: [] } } }, { type, payload }) => {

    switch (type) {
        case ALL_PRODUCTS_REQUEST:
        case SLIDER_PRODUCTS_REQUEST:
        case PRODUCTBYPRICE_DETAILS_REQUEST:
            return {
                loading: true,
                products: { searchedResult: [], filters: { brands: [], colors: [], sizes: [] } }
            };

        case ALL_PRODUCTS_SUCCESS:
        case PRODUCTBYPRICE_DETAILS_SUCCESS:
        case SLIDER_PRODUCTS_SUCCESS:
            return {
                loading: false,
                products: payload,
            };


        case ALL_PRODUCTS_FAIL:
        case SLIDER_PRODUCTS_FAIL:
        case PRODUCTBYPRICE_DETAILS_FAIL:
            return {
                loading: false,
                error: payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
}

export const productDetailsReducer = (state = { product: {} }, { type, payload }) => {

    switch (type) {
        case PRODUCT_DETAILS_REQUEST:
            return {
                product: {},
                loading: true,
            };
        case PRODUCT_DETAILS_SUCCESS:
            return {
                loading: false,
                product: payload,
            };
        case PRODUCT_DETAILS_FAIL:
            return {
                loading: false,
                error: payload,
            };
        // case REMOVE_PRODUCT_DETAILS:
        //     return {
        //         ...state,
        //         product: {},
        //     };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

export const categoryDetailsReducer = (state = { category : [] }, { type, payload }) => {

    switch (type) {
        case CATEGORY_DETAILS_REQUEST:
            return {
                product: {},
                loading: true,
            };
        case CATEGORY_DETAILS_SUCCESS:
            return {
                loading: false,
                category : payload.data,
            };
        case CATEGORY_DETAILS_FAIL:
            return {
                loading: false,
                error: payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}
