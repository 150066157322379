import React, { useState, useEffect } from 'react'
import Header from "../../Component/Header/Header"
import Footer from '../../Component/Footer/Footer';
import "./AddProduct.css"
import ProductForm from '../../Component/AddProduct/ProductForm';
import NewProductCategory from '../../Component/AddProduct/NewProductCategory';
import UploadImage from '../../Component/AddProduct/UploadImage';
import { useDispatch, useSelector } from 'react-redux';
import { createProduct, getCategories } from '../../actions/ProductAction';
import OvalLoader from "../../Loader/OvalLoader"
import { useNavigate } from 'react-router-dom';

function AddProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCategory, setShowCategory] = useState(true)
  const [showForm, setShowForm] = useState(false)
  const [showUploadImage, setShowUploadImage] = useState(false)
  const [imageFiles, setImageFiles] = useState([])
  const [uploadImageStatus, setUploadImageStatus] = useState("Pending")
  const [addDetailsStatus, setAddDetailsStatus] = useState("Pending")
  const [categoryStatus, setCategoryStatus] = useState("ongoing")
  const [productDetails, setProductDetails] = useState({})

  const { loading, product } = useSelector((state) => state.newproduct);

  useEffect(() => {
    dispatch(getCategories());
  }, [])

  // ONCHANGE FUNCTION FOR FORM INPUT
  const onChange = (e) => {
    setProductDetails({ ...productDetails, [e.target.name]: e.target.value })
  }
  let category = ""
  // let imageFiles = [];


  const handleAddCategory = (categoryString) => {
    category = categoryString
    setShowCategory(false)
    setShowUploadImage(true)
    setUploadImageStatus("Completed")
    setUploadImageStatus("ongoing")
  }

  const handleAddImages = (images) => {
    setImageFiles(images)
    setShowUploadImage(false);
    setShowForm(true);
    setProductDetails("Completed");
    setUploadImageStatus("Completed");
  }

  // API CALL TO SUBMIT FORM. //
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("productName", productDetails.productName);
    formData.append("productDescription", productDetails.productDescription);
    formData.append("productMRP", productDetails.productMRP);
    formData.append("productListingPrice", productDetails.productListingPrice);
    formData.append("productCategory", category);
    formData.append("productQuantity", productDetails.productQuantity);
    formData.append("productBrand", productDetails.productBrand);
    formData.append("productSize", productDetails.productSize);
    formData.append("productColor", productDetails.productColor);
    for (let i = 0; i < imageFiles.length; i++) {
      formData.append("productImages", imageFiles[i]);
    }
    dispatch(createProduct(formData));
    if (product.productName && product._id)
      navigate(`/product/${product.productName}/${product._id}`)
    alert("Product Added Successfully.")
  }



  return (
    <>
      <div className='header'>
        <Header />
      </div>

      {loading && <div className='addProduct'>
        <OvalLoader />
      </div>
      }

      {!loading && <div className='addProduct'>

        <div className='addCategoryContainer'>

          {showCategory ? <>
            <p className='categoryHeading' style={{ fontSize: "1.3rem", borderBottom: "solid 1px grey", fontWeight: "400", padding: "1rem", backgroundColor: categoryStatus === "ongoing" ? "#982E53" : "white", color: categoryStatus === "ongoing" ? "white" : "black" }}>Step 1 : Select Product Category</p>
            <NewProductCategory handleAddCategory={handleAddCategory} />
          </>
            :
            <div className='flexContainer'>
              <p style={{ fontSize: "1.3rem", fontWeight: "400", padding: "1rem", width: "40%" }}>Step 1 : Select Product Category</p>
              <div class="badge">Completed</div>
            </div>}
        </div>

        <div className='addProductImages'>

          {showUploadImage ? <>  <p style={{ fontSize: "1.3rem", borderBottom: "solid 1px grey", fontWeight: "400", padding: "1rem", backgroundColor: uploadImageStatus === "ongoing" ? "#982E53" : "white", color: categoryStatus === "ongoing" ? "white" : "black" }} >Step 2 : Add Product Images</p>
            <UploadImage handleAddImages={handleAddImages} /> </>
            :
            <div className='flexContainer'>
              <p style={{ fontSize: "1.3rem", fontWeight: "400", width: "40%", padding: "1rem" }}>Step 2 : Add Product Images</p>
              <div class="badge" style={{ backgroundColor: uploadImageStatus === "Pending" ? "red" : "green" }} >{uploadImageStatus}</div>
            </div>
          }

        </div>

        <div className='addProductForm' onChange={onChange} handleSubmit={handleSubmit}>

          {showForm ? <>  <p style={{ fontSize: "1.3rem", borderBottom: "solid 1px grey", fontWeight: "400", padding: "1rem" }}>Step 3 : Add Product Details</p>
            <ProductForm /> </>
            :
            <div className='flexContainer'>
              <p style={{ fontSize: "1.3rem", fontWeight: "400", padding: "1rem", width: "40%" }}>Step 3 : Add Product Details</p>
              <div class={`badge`} style={{ backgroundColor: addDetailsStatus === "Pending" ? "red" : "green" }} >{addDetailsStatus}</div>
            </div>}

        </div>
        <div className='submitBtnBox'><button type='submit' className='submitBtn' onClick={handleSubmit}>ADD PRODUCT</button></div>

      </div>}

      <div className='addProductFooter'>
        <Footer />
      </div>

    </>
  );
};

export default AddProduct