import React, { useEffect, useState } from 'react'
import Footer from '../../Component/Footer/Footer'
import Header from '../../Component/Header/Header'
import ProductCarousel from '../../Component/ProductCaraousel/ProductCarousel'
import Slider from '../../Component/DealSlider/DealSlider'
import "./Homepage.css"
import { dealData, furnitureData } from '../../Data/data'
import { useDispatch } from 'react-redux'
import { getProductByPrice } from '../../actions/ProductAction'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

function Homepage() {
  const topDeals = [{ rate: 99 }, { rate: 299 }, { rate: 399 }, { rate: 499 }, { rate: 999 }]
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const hostName = process.env.REACT_APP_API_HOST
  const [newArrival , setNewArrival]= useState([])

  const handleCircularCard = (rate) => {
    dispatch(getProductByPrice(rate))
    Navigate("/products")
  }

  const newArrivalProducts = async () => {
    const url = `${hostName}/api/Product/newArrived`;
    const { data } = await axios.get(url);
    setNewArrival(data);
  }

  useEffect(() => { newArrivalProducts() }, [])

  return (
    <>
      <Header />
      <div className='banner-slider-1'>
        <Slider class="homepage-slider-banner" />
      </div>


      <div className='productByRates'>
        <div className='topDeals'><p id="slider-text">Mind Blowing Deals</p></div>
        {topDeals.map((e) => { return <div className='circularCard' onClick={() => handleCircularCard(e.rate)} ><p className='under'>UNDER</p><p className='rate'>{e.rate}</p></div> })}
      </div>

      <div className='product-slider-2'>
        <ProductCarousel slidesToShow={5} slidesToScroll={2} Data={furnitureData} />
        <div className='topProducts'><p id="slider-text"> Featured Products</p></div>
      </div>

      <div className='product-slider-1'>
        <div className='newArrival'><p id="slider-text">New Arrival</p></div>
        <ProductCarousel slidesToShow={5} slidesToScroll={2} Data={newArrival} />
      </div>


      <div className='zzabApp'>
        <span className='text1'> TRY THE Zzabs APP</span>
        <span className='text2'>Buy, sell and find just about anything using the app on your mobile.</span>
        <div className='googlePlayBtn'></div>
      </div>
      <div className='homepage-footer'>
        <Footer />
      </div>

    </>
  )
}

export default Homepage