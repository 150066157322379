import React, { useState } from 'react'
import "./UploadImage.css"
import ProductCard from '../ProductCard/ProductCard';

function UploadImage({ handleAddImages }) {
  const [previewUrls, setPreviewUrls] = useState([]);
  const [images, setImages] = useState([]);

  // Handle Image Function to get preview  //

  // const handleImageChange = (event) => {
  //   const { files } = event.target;
  //   const uploadedImages = [];
  //   const uploadedPreviewUrls = [];

  //   for (let i = 0; i < files.length; i++) {
  //     const uploadedImage = files[i];
  //     uploadedImages.push(uploadedImage);

  //     const previewUrl = URL.createObjectURL(uploadedImage);
  //     uploadedPreviewUrls.push(previewUrl);
  //   }
  //   setPreviewUrls(uploadedPreviewUrls);
  //   // handleAddImages(files)
  // };

  const handleImageChange = (e) => {
    setImages([...e.target.files]);
    const { files } = e.target;
    const uploadedPreviewUrls = [];

    for (let i = 0; i < files.length; i++) {
      const uploadedImage = files[i];
      const previewUrl = URL.createObjectURL(uploadedImage);
      uploadedPreviewUrls.push(previewUrl);
    }
    setPreviewUrls(uploadedPreviewUrls);
  };

  return (
    <div className='uploadImages'>
      <input id="uploadInput" type="file" onChange={handleImageChange} multiple hidden />

      <div className='uploadImage'>
        <label htmlFor='uploadInput'><i className="uploadIcon uil uil-image-plus"></i></label>
      </div>

      <div className='preview-box'>
        {/* {previewUrls.length >= 1 ? <h2 className='heading-preview text-center'> </h2> : null} */}
        {previewUrls.map((previewUrl) => (<ProductCard localSrc={previewUrl} />))}
      </div>
      {/* {previewUrls.length >= 1 ? <button className='btn-addCategory'>Add</button> : null} */}
      {previewUrls.length >= 1 ? <button className='btn-addCategory' onClick={() => handleAddImages(images)}>Add</button> : null}
    </div>
  )
}

export default UploadImage