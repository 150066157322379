import React, { useState } from 'react'
import "./ProductCard.css"
import { Link } from 'react-router-dom';

function ProductCard(props) {
  // const hostName = "https://ecommerce-api.leetwolf.in";
  const serverName = process.env.REACT_APP_API_HOST
  const reactHostName = process.env.REACT_APP_HOST
  // const hostName = "http://localhost:1000";

  const { coverImagePath, productName, productPrice, productMRP, productID } = props;

  const handleCardClick = () =>{
 window.open(`${reactHostName}/product/${productName}/${productID}`)
  }

  return (

    props.localSrc ?
      <div className='product-card'>
        <img className='product-card-img' src={props.localSrc} />
      </div>
      :
      // <Link to={`/product/${productName}/${productID}`}>
        <div className='product-card' style={{ padding: "0.7rem" }} onClick={handleCardClick}>
          <img className='product-card-img' src={`${serverName}/${coverImagePath}`} />
          {(productPrice) ?
            <div className='product-card-body'>
              <div className='productNameContainer'>
                <p className='productName text-center'><span id="pName">{productName}</span></p>
              </div>
              <p className='productPrice text-center'><span id="MRP" >${productMRP}</span><span id="ListPrice"> ${productPrice}</span></p>
            </div> : null}
        </div>
      // </Link>

  )
}

export default ProductCard       