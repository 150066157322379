import { combineReducers } from 'redux';
import { userAddressReducer, userDetailsReducer, userProductReducer,addressDetailsReducer } from './UserReducer';
import { newProductReducer, productsReducer,productDetailsReducer, categoryDetailsReducer } from './ProductReducers';
import { userCartProductReducer } from './CartReducer';


const rootReducer = combineReducers({

    newproduct: newProductReducer,
    product: productsReducer,
    productDetails: productDetailsReducer,
    cart: userCartProductReducer,
    userProducts : userProductReducer,
    userDetails : userDetailsReducer,
    address : userAddressReducer,
    addressDetails : addressDetailsReducer,
    categoryDetails : categoryDetailsReducer
});

export default rootReducer