import React from 'react'
import { MagnifyingGlass } from 'react-loader-spinner'
import "./Loader.css"

function SearchLoader({height , width }) {
  return (
    <div className="loader-container">
    <MagnifyingGlass
  visible={true}
  height={height}
  width={width}
  ariaLabel="MagnifyingGlass-loading"
  wrapperStyle={{}}
  wrapperClass="MagnifyingGlass-wrapper"
  glassColor = 'white'
  color = '#982E53'
/>
</div>
  )
}

export default SearchLoader