import React, { useState } from 'react'
import "./FilterBar.css"
import { useDispatch, useSelector } from 'react-redux'
import OvalLoader from "../../Loader/OvalLoader";

function FilterBar() {
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(100000)
  const {loading , products } = useSelector((state) => state.product)
  const [filteredProducts, setFilteredProducts] = useState([])
  const dispatch = useDispatch();
  const filteredBrands = products.filters.brands.filter(e => e !== null);
  const filteredColors = products.filters.colors.filter(e => e !== null);
  const filteredSizes = products.filters.sizes.filter(e => e !== null);

  const [filters, setFilters] = useState({ price: { min: min, max: max }, size: [], color: [], brands: [] })

  const updatePriceFilter = () => {
    setFilters({ ...filters, price: { min, max } });
    handleFilters();
  }

  const updateSizeFilter = (size) => {
    if (filters.size.includes(size)) {
      setFilters({ ...filters, size: filters.size.filter(s => s !== size) });
    } else {
      setFilters({ ...filters, size: [...filters.size, size] });
    }
    handleFilters();  
  }

  const updateColorFilter = (color) => {
    if (filters.color.includes(color)) {
      setFilters({ ...filters, color: filters.color.filter(c => c !== color) });
    } else {
      setFilters({ ...filters, color: [...filters.color, color] });
    }
    handleFilters();
  }

  // const handleFilters = () => {
  //   let filteredProducts = products.data.searchedResult.filter(p => p.productListingPrice >= filters.price.min && p.productListingPrice <= filters.price.max);

  //   if (filters.size.length > 0) {
  //     filteredProducts = filteredProducts.filter(p => filters.size.includes(p.productSize));
  //   }
  //   if (filters.color.length > 0) {
  //     filteredProducts = filteredProducts.filter(p => filters.color.includes(p.productColor));
  //   }
  //   if (filters.brands.length > 0) {
  //     filteredProducts = filteredProducts.filter(p => filters.brands.includes(p.productBrand));
  //   }
  //   setFilteredProducts(filteredProducts);
  // }

  const handleFilters = () => {
// dispatch()

  }






  return (
    <>
      {/* {loading && <div className='filterBar'><OvalLoader height={50} width={50} /></div>} */}
      {!loading && <div className='filterBar'>
        <h3 id='Filters' style={{ color: "#982E53" }} >Filters</h3>
        <div className='priceFilter'>
          <input className='priceRangeInput' placeholder='Min' value={min} onChange={(e) => setMin(e.target.value)} />
          <input className="priceRangeInput" placeholder='Max' value={max} onChange={(e) => setMax(e.target.value)} />
          <button id='priceRangeBtn' onClick={updatePriceFilter}>Go</button>
        </div>

        {(filteredBrands && filteredBrands > 0) ?
          filteredBrands.map((brandName) => {
            return <div className='filter'>
              <h5 style={{ color: "#982E53" }}>Brand</h5>
              <div>
                <input className='mx-1' type="checkbox" value={`${brandName}`} />
                <label className='mx-1'>{brandName}</label>
              </div>
            </div>
          })
          : null
        }

        {(filteredColors && filteredColors.length > 0) ?
          <div className='filter'>
            <h5 style={{ color: "#982E53" }}>Colors</h5>
            {filteredColors.map((colors) => {
              return <div>
                <input className='mx-1' type="checkbox" value={`${colors}`} />
                <label className='mx-1'>{colors}</label>
              </div>
            })}
          </div> : null
        }

        {(filteredSizes && filteredSizes.length > 0) ?

          <div className='filter'>
            <h5 style={{ color: "#982E53" }}>Sizes</h5>
            {filteredSizes.map((sizes) => {
              return <div>
                <input className='mx-1' type="checkbox" checked={filters.size.includes(`${sizes}`)} onChange={(e) => updateSizeFilter(`${sizes}`)} value={`${sizes}`} />
                <label className='mx-1'>{sizes}</label>
              </div>
            })}
          </div> : null
        }

      </div>}
    </>
  )
}

export default FilterBar