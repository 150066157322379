import React from 'react'
import "./Footer.css"
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='row'>
        <div className='col-3'>
         <Link to="/"><div className='footer-logo'></div></Link>
        </div>
        <div className='col-6'>
          <div className='footer-list'>
            <ul>
              <Link><li>About zZabs</li></Link>
              <Link><li>Help</li></Link>
              <Link><li>Contact Us</li></Link>
              <Link><li>Legal and Privacy Information</li></Link>
            </ul>
          </div>
        </div>
        <div className='col-3'>
          <div className='followUs'>
            <span className='text-center mx-2'>FOLLOW US</span>
            <div className='footerIcons'>
               <Link><FaFacebook size= "1.5em"/></Link>
               <Link><FaInstagram className='mx-2' size= "1.5em"/></Link>
               <Link><AiFillTwitterCircle  size= "1.7em"/></Link>
            </div>
         <Link><div className='footerGooglePlayBtn'></div></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer