import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getProductDetails } from "../../actions/ProductAction";
import Header from "../../Component/Header/Header";
import OvalLoader from "../../Loader/OvalLoader";
import Footer from "../../Component/Footer/Footer"
import "./ProductDetails.css";
import { addProductToCart } from "../../actions/cartAction";
import axios from "axios";
import QuestionAnswer from "../../Component/ProductDetails/QuestionAnswer";

const ProductDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { product, loading } = useSelector((state) => state.productDetails)
    const hostName = "https://ecommerce-api.leetwolf.in"
    // const hostName = "http://localhost:1000"
    // const [selectedSize, setSelectedSize] = useState("");
    const [mainImgPath, setMainImgPath] = useState("nil")
    const [offerPrice, setOfferPrice] = useState(0)
    // const [quantity, setQuantity] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const ID = params._id
    const discountPercent = Math.round((product.productListingPrice * 100) / product.productMRP)


    // const handleSizeSelect = (e) => {
    //     setSelectedSize(e.target.value);
    // };

    // const handleQuantityChange = (e) => {
    //     setQuantity(e.target.value);
    // };

    // const addToCart = async () => {
    //     //    dispatch(addProductToCart(product._id))
    //     try {
    //         const config = { headers: { "auth-token": localStorage.getItem("eToken") } }
    //         const body = { "productID": product._id }
    //         await axios.post(`${hostName}/api/Product/addToCart`, body, config)
    //         navigate("/cart")
    //     }
    //     catch (error) {
    //         console.log(error)
    //     }

    // };

    const handleBuyNow=()=>{
        navigate("/product/checkout")
    }


    function handleOpenModal() {
        setIsModalOpen(!isModalOpen);
        // dispatch({ type: USER_ADDRESSDETAIL_RESET})
    }

    const onChange = (e) => {
        setOfferPrice(e.target.value)
    }

    function handleSubmit(event) {
        event.preventDefault();
    }


    const handleCoverImage = (path) => {
        setMainImgPath(`${hostName}/${path}`)
    }
    useEffect(() => {
        if (product && ID !== product.id)
            dispatch(getProductDetails(ID));
    }, [dispatch, ID]);

    return (
        <>
            <Header />

            {isModalOpen && (
                <div className='makeOfferModal'>

                    <form className="offerForm" onSubmit={handleSubmit}>
                        <h2 style={{ textAlign: "center" }} >Make An Offer</h2>

                        <div class="item">
                            <label className="addressFormLabel" >Offer Price (in $)</label>
                            <input className="addressFormInput" type="text" name="offerPrice" onChange={onChange} required />
                        </div>

                        <div className='addressFormBtnGroup'>
                            <button id="closeBtn" onClick={handleOpenModal}>CLOSE</button>
                            <button type='submit' id="addBtn">SEND OFFER</button>
                        </div>
                    </form>
                </div>
            )}

            {loading && <div style={{ background: "white" }} className="product-details-container"><OvalLoader width={50} height={50} /> </div>}

            {product.productImg !== undefined ? !loading && <div className="product-details-container">
                <div className="leftContainer">
                    <div className="product-image">

                        {mainImgPath === "nil" ?
                            <img className="coverImage" src={`${hostName}/${product.productImg[0]}`} alt={product.productName} />
                            : <img className="coverImage" src={mainImgPath} alt={product.productName} />}

                        <div className="thumbnailImageContainer">
                            {product.productImg.map((path, i) => {
                                return <img className="thumbnailImage" onClick={() => handleCoverImage(path)} src={`${hostName}/${path}`} alt={`Img${i}`} />
                            })
                            }
                        </div>
                    </div>

                    <div className="btnGroup">
                        <button className="add-to-cart" onClick={handleOpenModal}> Make Offer </button>
                        <button className="add-to-cart" onClick={handleBuyNow} > Buy Now </button>
                    </div>
                </div>

                <div className="product-info">
                    <p id="Brand">{product.productBrand}</p>

                    <div className="infoSection">
                        <p id="ProductName">{product.productName}</p>
                    </div>

                    <p><span id="productMRP">${product.productMRP}</span><span>&nbsp;</span>
                        <span id="ListingPrice">${product.productListingPrice}&nbsp;</span>
                        <span id="discountPercent">{discountPercent}% OFF</span></p>

                    {product.productQuantity > 0 ? <span style={{ fontSize: "1.2rem", color: "green", fontWeight: "600" }}>InStock</span>
                        : <span style={{ fontSize: "1.2rem", color: "red", fontWeight: "600" }}>Out Of Stock</span>}

                    <div className="product-options">
                        <div className="size-selector">
                            <label id="size" htmlFor="size-select">Size : &nbsp;</label>
                            <span id="sizeBox">{product.productSize}</span>
                        </div>

                    </div>

                    <div className="color-option">
                        <label id="size" htmlFor="size-select">Color : &nbsp;</label>
                        <span id="">{product.productColor}</span>
                    </div>

                    <div className="productDescriptionContainer">
                        <p id="About">About Product</p>
                        <p className="product-description">{product.productDescription}</p>
                    </div>
                </div>
            </div> : !loading && <div className="product-details-container">Product Not Found</div>}

            <div className="questionAnswerContainer">
                <QuestionAnswer productId = {product._id} sellerID = {product.userID} />
            </div>

            <div id="pageFooter">
                <Footer />
            </div>
        </>
    );
};

export default ProductDetails;
