import React from 'react'
import Register from "../../Component/Register&Auth/Login"
import ForgotPassword from "../../Component/Register&Auth/ForgotPassword"


function Login() {
    return (
        <>
        <Register/>
        </>

    )
}

export default Login