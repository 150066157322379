import axios from "axios";
import {
    ALL_PRODUCTS_FAIL, ALL_PRODUCTS_REQUEST, ALL_PRODUCTS_SUCCESS, PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL, CLEAR_ERRORS, NEW_PRODUCT_REQUEST, NEW_PRODUCT_SUCCESS, NEW_PRODUCT_FAIL, UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAIL, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAIL,
    SLIDER_PRODUCTS_REQUEST, SLIDER_PRODUCTS_SUCCESS, SLIDER_PRODUCTS_FAIL,
    PRODUCTBYPRICE_DETAILS_REQUEST, PRODUCTBYPRICE_DETAILS_SUCCESS, PRODUCTBYPRICE_DETAILS_FAIL,
    CATEGORY_DETAILS_REQUEST,CATEGORY_DETAILS_SUCCESS,CATEGORY_DETAILS_FAIL
} from "../constants/ProductConstants";

const hostName = process.env.REACT_APP_API_HOST;
const token = localStorage.getItem("eToken")

// Add New Product 
export const createProduct = (productData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PRODUCT_REQUEST });
        const confg = {
            headers: {
                'Content-Type': 'multipart/form-data',
                "auth-token": token
            }
        }
        const { data } = await axios.post(`${hostName}/api/Product/addProduct`, productData, confg);
        dispatch({
            type: NEW_PRODUCT_SUCCESS,
            payload: data,
        });
    }
    catch (error) {
        dispatch({
            type: NEW_PRODUCT_FAIL,
            payload: error.response,
        });
    }
}

// Get Products from search/filter/sort.

export const getProducts = (keyword) => async (dispatch) => {
    try {
        dispatch({ type: ALL_PRODUCTS_REQUEST });

        const search = keyword.search
        const color = keyword.color || ""
        const size = keyword.size || ""
        const brand = keyword.brand || ""

        let url = `${hostName}/api/Product/searchProduct?search=${search}&productColor=${color}&productSize=${size}&ProductBrand=${brand}`;
        const { data } = await axios.get(url);

        dispatch({
            type: ALL_PRODUCTS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ALL_PRODUCTS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get Product Details
export const getProductDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_DETAILS_REQUEST });

        const { data } = await axios.get(`${hostName}/api/Product/productDetails/${id}`);

        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get Product By Price
export const getProductByPrice = (price) => async (dispatch) => {
    try {
        dispatch({ type: PRODUCTBYPRICE_DETAILS_REQUEST });

        const { data } = await axios.get(`${hostName}/api/Product/productsByPrice?price=${price}`);
        // console.log(data);

        dispatch({
            type: PRODUCTBYPRICE_DETAILS_SUCCESS,
            payload: data,
        });
    }
    catch (error) {
        dispatch({
            type: PRODUCTBYPRICE_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getCategories = () => async (dispatch) =>{
    try {
        dispatch({ type: CATEGORY_DETAILS_REQUEST });

        const { data } = await axios.get(`${hostName}/api/admin/getAllCategories`);
        // console.log(data);

        dispatch({
            type: CATEGORY_DETAILS_SUCCESS,
            payload: data,
        });
    }
    catch (error) {
        dispatch({
            type: CATEGORY_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Clear All Errors
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}