import React, { useState } from 'react'
import "./ProductForm.css"

function ProductForm({onChange} , {handleSubmit}) {
   

  
    return (
        <>
            <form className='addProductForm' encType="multipart/form-data" onSubmit={handleSubmit} >
                <div className='productDetails'>
                    <div className='col1'>
                        <label htmlFor="productName" className='productForm-label'>Product Name</label>
                        <input name="productName" className='productForm-input' onChange={onChange} type="text" />

                        <label htmlFor="productBrand" className='productForm-label'>Brand</label>
                        <input name="productBrand" className='productForm-input' onChange={onChange} type="text" />

                        <label htmlFor="productColor" className='productForm-label'>Color</label>
                        <input name="productColor" className='productForm-input' onChange={onChange} type="text" />

                        <label htmlFor="productQuantity" className='productForm-label'>Quantity</label>
                        <input name="productQuantity" className='productForm-input' onChange={onChange} type="number" />

                        <label htmlFor="productSize" className='productForm-label'>Size</label>
                        <input name="productSize" className='productForm-input' onChange={onChange} type="text" />

                    </div>

                    <div className='col2'>

                        <label htmlFor="productMRP" className='productForm-label'>Original Price</label>
                        <input name="productMRP" className='productForm-input' onChange={onChange} type="number" />

                        <label htmlFor="productListingPrice" className='productForm-label'>Listing Price</label>
                        <input name="productListingPrice" onChange={onChange} className='productForm-input' type="number" />

                        <label htmlFor="productDescription" className='productForm-label '>What are you Selling? </label>
                        <span style={{ fontSize: "0.7rem" }}> * Share key details about product.</span>
                        <textarea name="productDescription" className='productForm-input' onChange={onChange} type="text" />
                        

                    </div>
                </div>
            </form >
        </>
    )
}

export default ProductForm