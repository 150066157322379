import axios from "axios";
import { getShippingAddress } from "../actions/userAction";
const hostName = process.env.REACT_APP_API_HOST
const token = localStorage.getItem("eToken")

// ADDRESS API
export const addAddress = async (data, dispatch) => {
    try {

        const confg = {
            headers: {
                "auth-token": token
            }
        }
        await axios.post(`${hostName}/api/user/addShippingAddress`, data, confg)
        dispatch(getShippingAddress())

    } catch (error) {
        console.log(error)
    }

}

export const updateAddressDetails = async (addressID, updatedData, dispatch) => {
    try {
        const confg = {
            headers: {
                "auth-token": token
            }
        }
        await axios.put(`${hostName}/api/user/${addressID}/edit`, updatedData, confg)
        dispatch(getShippingAddress())
    } catch (error) {
        console.log(error)
    }

}

export const deleteAddress = async (addressID, dispatch) => {
    try{
    const confg = {
        headers: {
            "auth-token": token
        }
    }
    await axios.delete(`${hostName}/api/user/deleteAddress/${addressID}`, confg)
    dispatch(getShippingAddress())
}
catch(error){
    console.log(error)
}
}