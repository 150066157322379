import { USER_CART_REQUEST, USER_CART_SUCCESS, USER_CART_FAIL } from "../constants/CartConstants";

const initialState = { loading: false, error: null }

export const userCartProductReducer = (state = { ...initialState, cartProducts: [] }, { type, payload }) => {
    switch (type) {
        case USER_CART_REQUEST:
            {
                return {
                    ...state,
                    loading: true
                }
            };
        case USER_CART_SUCCESS:
            {
                return {
                    cartProducts: payload,
                    loading: false
                }
            };
        case USER_CART_FAIL:
            {
                return {
                    ...state,
                    loading: false,
                    error: payload,
                }
            };

        default: {
            return state
        }

    }
}
// export const userCart = (state = { ...initialState, cartProducts: [] }, { type, payload }) => {
//     switch (type) {
//         case USER_CART_REQUEST:
//             {
//                 return {
//                     ...state,
//                     loading: true
//                 }
//             };
//         case USER_CART_SUCCESS:
//             {
//                 return {
//                     cartProducts: payload,
//                     loading: false
//                 }
//             };
//         case USER_CART_FAIL:
//             {
//                 return {
//                     ...state,
//                     loading: false,
//                     error: payload,
//                 }
//             };

//         default: {
//             return state
//         }

//     }
// }