import axios from "axios";
import { USER_CART_REQUEST, USER_CART_SUCCESS, USER_CART_FAIL } from "../constants/CartConstants";
const hostName = process.env.REACT_APP_API_HOST;
const token = localStorage.getItem("eToken")

export const getUserCartProducts = () => async (dispatch) => {
    try {
        dispatch({ type: USER_CART_REQUEST })
        const config = { headers: { "auth-token": token } }

        const { data } = await axios.get(`${hostName}/api/auth/cartDetails`, config)

        var cartProducts = []

        cartProducts = await Promise.all(
            data.products.map(async (product) => {
                const id = product.productID
                const { data } = await axios.get(`${hostName}/api/Product/productDetails/${id}`)
                return({...data , cartProductQuantity : product.quantity})
                // return data        
            })
        )

        dispatch({
            type: USER_CART_SUCCESS,
            payload: cartProducts
        })

    }
    catch (error) {
        dispatch({
            type: USER_CART_FAIL,
            payload: error.response.data.message,
        })
    }
}


export const addProductToCart = (productDetail) => async (dispatch) => {
    try {
        dispatch({ type: USER_CART_REQUEST })
        const config = { headers: { "auth-token": token } };
        const body = productDetail;

        await axios.post(`${hostName}/api/Product/addToCart`, body, config)
        alert("Successfully added To Cart")

    }
    catch (error) {
        alert("Unable to add product to cart")
    }
}
