import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from "axios"

function Login() {
    const hostName = process.env.REACT_APP_API_HOST
    const navigate = useNavigate();
    const [input, setInput] = useState({});


    const onChange = ((e) => {
        setInput({ ...input, [e.target.name]: e.target.value })
    })

    const handleLogin = async (e) => {
        e.preventDefault()
        const url = `${hostName}/api/auth/login`
        var body = { "emailID": input.email, "password": input.password , "userType" : "user"};

        const { data } = await axios.post(url, body)
        localStorage.setItem("eToken", data.authtoken)
        navigate("/")
    }

    return (
        <>
            <div className="e140_312">
                <div className="image_box"></div>
                <form className='signupForm' onSubmit={handleLogin} >
                    <h2 className='fw-bold text-center'>Login</h2>

                    <p className='text-center'>Please enter your login details.</p>

                    <label className="form-label mt-3">Email</label>
                    <input className="form-input" name="email" onChange={onChange} type='email' placeholder='Email'></input>
                    <label className="form-label mt-3">Password</label>
                    <input className="form-input" name="password" onChange={onChange} type='password' placeholder='password'></input>

                    <div className='mt-4'>
                        <button type='submit' className="sub_btn">Send</button>
                    </div>

                    <div className='accountSwitch'>
                        <label className="mt-1">Do not have an account?</label>
                        <Link className="fw-bold mx-1" to="/signup" >SIGNUP</Link>
                        <label className='fw-bold mx-1'> | </label>
                        <label className='fw-bold mx-1'>Forgot Password</label>
                        
                    </div>
                    <p className='accountSwitch mt-2'>Skip Login <Link className="fw-bold mx-1" to="/" >Click Here</Link></p>
                </form>
            </div>
        </>
    )
}

export default Login