import axios from "axios"
import {
    USER_PROFILE_REQUEST, USER_PROFILE_SUCCESS, USER_PROFILE_FAIL, USER_PROFILE_RESET, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_RESET, UPDATE_PROFILE_FAIL, USER_PRODUCT_REQUEST,
    USER_PRODUCT_SUCCESS, USER_PRODUCT_FAIL, USER_PRODUCT_RESET, USER_ORDERS_REQUEST, USER_ORDERS_SUCCESS, USER_ORDERS_FAIL,
    USER_ADDRESS_REQUEST, USER_ADDRESS_SUCCESS, USER_ADDRESS_RESET, USER_ADDRESS_FAIL, UPDATE_ADDRESS_REQUEST, UPDATE_ADDRESS_SUCCESS, UPDATE_ADDRESS_RESET, UPDATE_ADDRESS_FAIL,
    USER_ADDRESSDETAIL_REQUEST, USER_ADDRESSDETAIL_SUCCESS, USER_ADDRESSDETAIL_RESET, USER_ADDRESSDETAIL_FAIL,
} from "../constants/userConstants"

const hostName = process.env.REACT_APP_API_HOST;
const token = localStorage.getItem("eToken")

// Get User Basic Details 
export const getUserProfile = () => async (dispatch) => {
    try {
        dispatch({ type: USER_PROFILE_REQUEST });
        const confg = {
            headers: {
                "auth-token": token
            }
        }
        const { data } = await axios.get(`${hostName}/api/user/userDetails`, confg);
        dispatch({
            type: USER_PROFILE_SUCCESS,
            payload: data,
        });
    }
    catch (error) {
        dispatch({
            type: USER_PROFILE_FAIL,
            payload: error.response,
        });
    }
}

// Update User Basic Details 
export const updateUserProfile = (details) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROFILE_REQUEST });
        const confg = {
            headers: {
                "auth-token": token
            }
        }
        const { data } = await axios.put(`${hostName}/api/user/editUser`, details, confg);
        dispatch({
            type: UPDATE_PROFILE_SUCCESS,
            payload: data,
        });
    }
    catch (error) {
        dispatch({
            type: UPDATE_PROFILE_FAIL,
            payload: error.response,
        });
    }
}

//  User Shipping Address 
export const getShippingAddress = () => async (dispatch) => {
    try {
        dispatch({ type: USER_ADDRESS_REQUEST });
        const confg = {
            headers: {
                "auth-token": token
            }
        }
        const { data } = await axios.get(`${hostName}/api/user/getUserAddress`, confg);
        dispatch({
            type: USER_ADDRESS_SUCCESS,
            payload: data,
        });
    }
    catch (error) {
        dispatch({
            type: USER_ADDRESS_FAIL,
            payload: error.response,
        });
    }
}
// Update User Shipping Address 
export const updateAddressDetails = () => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_ADDRESS_REQUEST });
        const confg = {
            headers: {
                "auth-token": token
            }
        }
        const { data } = await axios.get(`${hostName}/api/user/getUserAddress`, confg);
        dispatch({
            type: UPDATE_ADDRESS_SUCCESS,
            payload: data,
        });
    }
    catch (error) {
        dispatch({
            type: UPDATE_ADDRESS_FAIL,
            payload: error.response,
        });
    }
}

//  User Shipping Address by ID
export const getAddressDetails = (addressID) => async (dispatch) => {
    try {
        dispatch({ type: USER_ADDRESSDETAIL_REQUEST });
        const confg = {
            headers: {
                "auth-token": token
            }
        }
        const { data } = await axios.get(`${hostName}/api/user/getAddress/${addressID}`, confg);
        dispatch({
            type: USER_ADDRESSDETAIL_SUCCESS,
            payload: data,
        });
    }
    catch (error) {
        dispatch({
            type: USER_ADDRESSDETAIL_FAIL,
            payload: error.response,
        });
    }
}

// Get User Products
export const getUserProducts = () => async (dispatch) => {

    try {
        dispatch({ type: USER_PRODUCT_REQUEST });
        const confg = {
            headers: {
                "auth-token": token
            }
        }
        const { data } = await axios.get(`${hostName}/api/Product/userProduct`, confg);

        dispatch({
            type: USER_PRODUCT_SUCCESS,
            payload: data,
        });
    }
    catch (error) {
        dispatch({
            type: USER_PRODUCT_FAIL,
            payload: error.response,

        });
    }
}

// Get User Orders
export const getUserOrders = () => async (dispatch) => {

    try {
        dispatch({ type: USER_ORDERS_REQUEST });
        const confg = {
            headers: {
                "auth-token": token
            }
        }
        const { data } = await axios.get(`${hostName}/api/user/order/userOrders`, confg);

        dispatch({
            type: USER_ORDERS_SUCCESS,
            payload: data,
        });
    }
    catch (error) {
        dispatch({
            type: USER_ORDERS_FAIL,
            payload: error.response,
        });
    }
}
