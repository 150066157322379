import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAddressDetails } from '../../actions/userAction';
import { USER_ADDRESSDETAIL_RESET } from '../../constants/userConstants';
import OvalLoader from '../../Loader/OvalLoader';
import { deleteAddress } from '../../services/api';
import AddressForm from './AddressForm';
import "./UserAddress.css"

function UserAddress() {
    const { loading, userAddress } = useSelector((state) => state.address)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();

    function handleOpenModal() {
        setIsModalOpen(!isModalOpen);
        dispatch({ type: USER_ADDRESSDETAIL_RESET})
    }

    const handleEdit = (addressID) => {
        setIsModalOpen(!isModalOpen);
        dispatch(getAddressDetails(addressID))
    }

    return (
        <>
            {loading && <OvalLoader />}
            {!loading &&
                <>
                    {userAddress.map((e) =>
                        <div className='addressContainer my-3'>
                            <div className="addressLeft ">
                                <p>
                                    <span className='fw-bold'>{e.firstName} {e.lastName} 
                                    </span> &nbsp; &nbsp; &nbsp;<span className='fw-bold'>{e.phone}</span>
                                    </p>
                                <p>{e.address} , {e.city} , {e.state} </p>
                                <p className='fw-bold'> {e.zip}</p>
                            </div>
                            <div className="addressRight">
                                <i className="uil uil-ellipsis-v" style={{ fontSize: "1.3rem" }}></i>
                                <div className="options">
                                    <div className="option" onClick={() => handleEdit(e._id)}>
                                        <i className="uil uil-edit"></i>
                                        <span>Edit</span>
                                    </div>
                                    <div className="option" onClick={() => deleteAddress(e._id , dispatch)}>
                                        <i className="uil uil-trash-alt"></i>
                                        <span>Delete</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )}

                    <div className='addAddress' onClick={handleOpenModal} >
                        <span>Add New Address</span> <i class="uil uil-plus"></i>
                    </div>
                </>
            }

            {isModalOpen && (
                <div className="addressModal">
                    <AddressForm OpenModal={handleOpenModal} />
                </div>
            )}


        </>

    )
}

export default UserAddress