import React,{useState} from 'react'
import "./Sidebar.css"

function Sidebar(props) {
    // const [selectedItem, setSelectedItem] = useState('');

    // const handleItemClick = (item) => {
    //     setSelectedItem(item);
    // }

    return (
        <div className='dashboardSidebar'>
                <ul className='sidebar-ul'>
                    <li className='sidebarList' onClick={() => props.handleItemClick('Basic Details')}>Basic Details</li>
                    <li className='sidebarList' onClick={() => props.handleItemClick('My Products')}>User Products</li>
                    <li className='sidebarList' onClick={() => props.handleItemClick('My Orders')}>My Orders</li>
                    <li className='sidebarList' onClick={() => props.handleItemClick('My Addresses')}>My Addresses</li>
                </ul>

                <ul className='logout'>
                    <button className='lgout-btn'>LOGOUT</button>
                </ul>
        </div>
    )
}

export default Sidebar