import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import "./Header.css"
import Menubox from './Menubox'
import { getCategories, getProducts } from "../../actions/ProductAction"


function Header() {
  const [search, setSearch] = useState("");
  const [display, setDisplay] = useState("hideMenu")
  const [activeMen, setActiveMen] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleShowMenu = () => {
    if (display === "showMenu") {
      setDisplay("hideMenu")
    }
    else {
      setDisplay("showMenu")
    }
    setActiveMen(true)
  }


  const onChange = (e) => {
    setSearch(e.target.value)
  }

  const handleSearch = (e) => {
    e.preventDefault()
    dispatch(getProducts({ search : search }))
    navigate("/products")
  }

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const openCart = () => {
    if (localStorage.getItem("eToken")) {
      navigate("/cart")
    }
    else {
      navigate("/login")
    }
  }

  const openSellPage = () => {
    if (localStorage.getItem("eToken")) {
      navigate("/addProduct")
    }
    else {
      navigate("/login")
    }
  }

  const handleLogout = () => {
    (localStorage.removeItem("eToken"))
    navigate("/login")
  }

  useEffect(() => {
    dispatch(getCategories());
  }, [])
  return (
    <>
      <div className='navbar'>
        <Link to="/"> <div className='header-logo'></div></Link>
        <button className='menuBtn'><i className="uil uil-align-justify" onClick={handleShowMenu}></i></button>

        <form className='searchBar' >
          <input className='search' type="search" onKeyDown={handleKeypress} onChange={onChange} style={{ border: "none" }} />
          <i className="uil uil-search" onClick={handleSearch} type="submit" style={{ backgroundColor: "white" }}></i>
        </form>

        <button type="button" className='Sell-btn' onClick={openSellPage} > Sell <i class="uil uil-plus-circle"></i></button>

        <ul className='nav'>

          {localStorage.getItem("eToken") ?
            <div className='dropdown'>
              <li className='nav-item mx-1'>Shubham</li>
              <div class="dropdown-content">
                <Link to="/account/profile"><i class="uil uil-user"> Profile</i></Link>
                <Link to="/"><i class="uil uil-parcel"> Products</i> </Link>
                <Link to="/"><i class="uil uil-truck"> Orders</i></Link>
                <Link to="/" onClick={handleLogout} ><i class="uil uil-signout" > Log Out</i></Link>
              </div>
            </div> : <Link to="/login"> <li className='nav-item mx-1'>Login</li></Link>}

          {/* <li className='nav-item mx-2' onClick={openCart}><i className="uil uil-shopping-cart"></i> Cart</li> */}
        </ul>
      </div>
      <div className={display} ><Menubox activeMenProp={activeMen} /> </div>
    </>
  )
}

export default Header