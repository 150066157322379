
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AddProduct from './Pages/Add Product/AddProduct';
import Cart from './Pages/Cart/Cart';
import Checkout from './Pages/Checkout/Checkout';
import Homepage from './Pages/Homepage/Homepage';
import ProductDetails from './Pages/ProductDetails/ProductDetails';
import Products from './Pages/Products/Products';
import Login from './Pages/Signup/Login';
import Signup from './Pages/Signup/Signup';
import UserProfile from "./Pages/Profile/Profile"
// import { useEffect } from 'react';


function App() {
  // const { pathname } = useLocation();

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth"
  //   });
  // }, [pathname])

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/addProduct" element={<AddProduct />} />
        <Route path="/products" element={<Products />} />
        <Route path="/product/:name/:_id" element={<ProductDetails />} />
        <Route path="/product/checkout" element={<Checkout />} />
        <Route path="/account/profile" element={<UserProfile />} />
      </Routes>
    </Router>

  );
}

export default App;
