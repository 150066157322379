import React from 'react'
import "./Subtotal.css"
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';

function Subtotal(props) {
  var listingPrice = props.listingPrice;
  var MRP = props.MRP;
  // const { cartProducts, loading } = useSelector((state) => state.cart)
  const { product, loading } = useSelector((state) => state.productDetails)
  const hostName = process.env.REACT_APP_API_HOST;

  // cartProducts.map((e) => { listingPrice = listingPrice + e.productListingPrice })

  // cartProducts.map((e) => { MRP = MRP + e.productMRP })

  // let count = cartProducts.length;
  let discount = MRP - listingPrice;

  const makePayment = async()=>{
    const stripe = await loadStripe("pk_test_51KFIlYLyOZRvOolizdzPuxV4ehRCah2LdNMo34tuKhE6ZosWFzFKnERJ8lbCSFyO7HjxUfTZQW31ArKck4xww09x009Iw1gNrS");

    const body = {
        products:[{ productName : product.productName,
                   productPrice : product.productListingPrice}]
    }
    const headers = {
        "Content-Type":"application/json"
    }
    const response = await fetch(`${hostName}/api/payment/create-checkout-session`,{
        method:"POST",
        headers:headers,
        body:JSON.stringify(body)
    });

    const session = await response.json();

    const result = stripe.redirectToCheckout({
        sessionId:session.id
    });
    
    if(result.error){
        console.log(result.error);
    }
}


  return (
    <div className='subTotalContainer'>
      <h5 className=''>PRICE DETAILS</h5>

      <div className="subTotalDetails">
        <div className='details'>
          {/* <span>Price({count} Items)</span> */}
          <span>Price</span>
          <span>$ {MRP}</span>
        </div>
        <div className='details'>
          <span>Discount</span>
          <span>- ${discount}</span>
        </div>
        <div className='details'>
          <span> Delivery Charges</span>
          <span>FREE</span>
        </div>
      </div>
      <div className='totalPrice details'>
        <span className=''>Total Price</span>
        <span className=''>$ {listingPrice}</span>
      </div>
    <button className='buy-btn' onClick={makePayment}>Proceed To Payment</button>
    </div>
  )
}

export default Subtotal