import React from 'react'
import { Oval } from 'react-loader-spinner'
import "./Loader.css"

function OvalLoader({height , width }) {
  return (
    <div className="loader-container">
    <Oval
        height={height}
        width={width}
        color='#982E53'
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor='#982E53'
        strokeWidth={2}
        strokeWidthSecondary={2}

    />
</div>
  )
}

export default OvalLoader