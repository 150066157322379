import React, { useState } from 'react'
import "./Header.css"
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Menubox(props) {
  const { loading, category } = useSelector((state) => state.categoryDetails)
  const [menActive, setMenActive] = useState(props.activeMenProp)
  const [womenActive, setWomenActive] = useState(false)
  const [kidActive, setKidActive] = useState(false)
  const [subCategories, setSubCategories] = useState([])

  const handleClickCategory = (subCategories) => {
    setSubCategories(subCategories)
    setKidActive(false)
    setWomenActive(false)
    setMenActive(true)
  }



  return (


    <div className='menuBox'>

      <div className='menu'>
        {!loading && category.map((e) => {
          return <button className={`mainCategory${e.name ? " active" : ""}`} onClick={() => handleClickCategory(e.subCategories)}>{e.name}'s Fashion</button>
        })}
      </div>

      <div className='category'>

        {subCategories.map((e) => {

          return<div className='categoryMenu'>
            <span className='subMenuHeading'>{e.name}</span>
            {e.subSubCategories.map((subCategory) => (
            <li className="sub-menu" key={subCategory._id} >{subCategory.name}</li>
          ))}
          </div>
        })}


      </div>
    </div>


  )
}

export default Menubox