import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import OvalLoader from '../../Loader/OvalLoader';
import { addAddress, updateAddressDetails } from '../../services/api';

function AddressForm({ OpenModal }) {
    const [details, setDetails] = useState({})
    const { loading, addressDetails } = useSelector((state) => state.addressDetails)
    const dispatch = useDispatch()



    function handleSubmit(event) {
        event.preventDefault();
        if (addressDetails._id) {
            updateAddressDetails(addressDetails._id, details, dispatch)
        }
        else {
            addAddress(details,dispatch)
        }
        OpenModal()
    }


    const onChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }
    
    return (
        <>
            {loading && <OvalLoader />}
            {!loading && <form onSubmit={handleSubmit}>

                {addressDetails ? <h3 className='addressFormHeader'>Edit Address Details</h3> : <h3 className='addressFormHeader'>Add New Address</h3>}
                <div class="colums">
                    <div class="item">
                        <label className="addressFormLabel" >First Name<span>*</span></label>
                        <input className="addressFormInput" type="text" name="firstName" onChange={onChange} defaultValue={addressDetails ? addressDetails.firstName : ""} required />
                    </div>
                    <div class="item">
                        <label className="addressFormLabel" >Last Name<span>*</span></label>
                        <input className="addressFormInput" type="text" name="lastName" onChange={onChange} defaultValue={addressDetails ? addressDetails.lastName : ""} required />
                    </div>
                    <div class="item">
                        <label className="addressFormLabel" >Email Address<span>*</span></label>
                        <input className="addressFormInput" type="email" name="email" onChange={onChange} defaultValue={addressDetails ? addressDetails.email : ""} required />
                    </div>
                    <div class="item">
                        <label className="addressFormLabel" >Phone<span>*</span></label>
                        <input className="addressFormInput" type="number" name="phone" onChange={onChange} defaultValue={addressDetails ? addressDetails.phone : ""} min={10} max={10} required />
                    </div>
                    <div class="item">
                        <label className="addressFormLabel" >Address<span>*</span></label>
                        <input className="addressFormInput" type="text" name="address" onChange={onChange} defaultValue={addressDetails ? addressDetails.address : ""} required />
                    </div>
                    <div class="item">
                        <label className="addressFormLabel" for="state">State<span>*</span></label>
                        <input className="addressFormInput" id="state" type="text" name="state" onChange={onChange} defaultValue={addressDetails ? addressDetails.state : ""} required />
                    </div>
                    <div class="item">
                        <label className="addressFormLabel" >Zip/Postal Code<span>*</span></label>
                        <input className="addressFormInput" type="text" name="zip" onChange={onChange} defaultValue={addressDetails ? addressDetails.zip : ""} required />
                    </div>
                    <div class="item">
                        <label className="addressFormLabel" >City<span>*</span></label>
                        <input className="addressFormInput" type="text" name="city" onChange={onChange} defaultValue={addressDetails ? addressDetails.city : ""} required />
                    </div>
                </div>
                <div className='addressFormBtnGroup'>
                    <button id="closeBtn" onClick={OpenModal}>CLOSE</button>
                    <button type='submit' onClick={handleSubmit} id="addBtn">SAVE</button>
                </div>
            </form>}
        </>
    )
}

export default AddressForm