import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getShippingAddress, getUserOrders, getUserProducts, getUserProfile } from '../../actions/userAction'
import Footer from '../../Component/Footer/Footer'
import Header from '../../Component/Header/Header'
import BasicDetails from '../../Component/UserProfile/BasicDetails'
import Sidebar from '../../Component/UserProfile/Sidebar'
import UserAddress from '../../Component/UserProfile/UserAddress'
import UserProducts from '../../Component/UserProfile/UserProducts'
import "./Profile.css"
import OvalLoader from "../../Loader/OvalLoader"
import { Link } from 'react-router-dom'

function Profile() {
    const [userProfile, setUserProfile] = useState(null);
    const [selectedItem, setSelectedItem] = useState("Basic Details");
    const dispatch = useDispatch();
    // const  userProducts = useSelector((state) => state.userProducts)
    const { loading, userProducts } = useSelector((state) => state.userProducts)

    useEffect(() => {
        dispatch(getUserProfile())
    }, []);

    const handleItemClick = (item) => {
        setSelectedItem(item);
        if (item === 'Basic Details') {
            dispatch(getUserProfile())
        }
        if (item === 'My Products') {
            dispatch(getUserProducts())
        }
        if (item === 'My Orders') {
            dispatch(getUserOrders())
        }
        if (item === 'My Addresses') {
            dispatch(getShippingAddress())
        }
    };

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are 0-based
        const year = date.getFullYear() % 100; // Get the last two digits of the year
      
        // Ensure leading zeros for single-digit days and months
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedYear = year < 10 ? `0${year}` : year;
      
        return `${formattedDay}/${formattedMonth}/${formattedYear}`;
      };

    return (
        <>
            <Header />
            <div className='userProfileContainer'>
                <div className='profileLeftContainer'>
                    <Sidebar selectedItem={selectedItem} handleItemClick={handleItemClick} />
                </div>
                <div className='profileRightContainer'>
                    <p className='header'><span className='fw-bold'>{selectedItem}</span></p>
                    {selectedItem === 'Basic Details' && <BasicDetails />}
                    {selectedItem === 'My Products' &&
                        <div className='profileProducts'>
                            {loading && <OvalLoader width={40} height={40} />}
                            {!loading && userProducts &&
                                userProducts.map((e) => {
                                    return <Link className='productLink' to={`/product/${e.productName}/${e._id}`}> <UserProducts imagePath={e.productImg[0]} productName={e.productName}
                                        MRP={e.productMRP} listingPrice={e.productListingPrice} color={e.productColor} createdAt = {formatDate(e.createdAt)} /> </Link>
                                })}

                        </div>
                    }
                    {selectedItem === 'My Orders' && <div className='profileProducts'>
                        {loading && <OvalLoader width={40} height={40} />}
                        {!loading && userProducts &&
                            userProducts.map((e) => {
                                return <UserProducts imagePath={e.productImg[0]} productName={e.productName}
                                    MRP="" listingPrice={e.totalPrice}  />
                            })}
                    </div>}
                    {selectedItem === 'My Addresses' && <div className='profileProducts'> <UserAddress /></div>}
                </div>
            </div>
            <div className='profileFooter'>
                <Footer />
            </div>
        </>
    )
}

export default Profile