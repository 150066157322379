import {
    USER_PROFILE_REQUEST, USER_PROFILE_SUCCESS, USER_PROFILE_RESET, USER_PROFILE_FAIL, USER_PRODUCT_REQUEST, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_RESET, UPDATE_PROFILE_FAIL,
    USER_PRODUCT_SUCCESS, USER_PRODUCT_FAIL, USER_PRODUCT_RESET, USER_ORDERS_REQUEST, USER_ORDERS_SUCCESS, USER_ORDERS_FAIL,
    USER_ADDRESS_REQUEST, USER_ADDRESS_SUCCESS, USER_ADDRESS_RESET, USER_ADDRESS_FAIL,USER_ADDRESSDETAIL_REQUEST, USER_ADDRESSDETAIL_SUCCESS, USER_ADDRESSDETAIL_RESET, USER_ADDRESSDETAIL_FAIL,
} from "../constants/userConstants"

const initialState = { loading: false, error: null }

//User Details Reducer
export const userDetailsReducer = (state = { ...initialState, userDetails: {} }, { type, payload }) => {
    switch (type) {
        case USER_PROFILE_REQUEST:
        case UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USER_PROFILE_SUCCESS:
        case UPDATE_PROFILE_SUCCESS:
            return {
                loading: false,
                userDetails: payload,
            };
        case USER_PROFILE_FAIL:
        case UPDATE_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case USER_PROFILE_RESET:
        case UPDATE_PROFILE_RESET:
            return {
                ...state,
                success: false,
            };
        default:
            return state;
    }
}

// User Product and Order Reducer
export const userProductReducer = (state = { ...initialState, userProducts: [] }, { type, payload }) => {
    switch (type) {
        case USER_ORDERS_REQUEST:
        case USER_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USER_PRODUCT_SUCCESS:
        case USER_ORDERS_SUCCESS:
            return {
                loading: false,
                userProducts: payload,
            };
        case USER_PRODUCT_FAIL:
        case USER_ORDERS_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case USER_PRODUCT_RESET:
            return {
                ...state,
                success: false,
            };
        default:
            return state;
    }
}

// User All Shipping Address Reducer
export const userAddressReducer = (state = { ...initialState, userAddress: [] }, { type, payload }) => {
    switch (type) {
        case USER_ADDRESS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USER_ADDRESS_SUCCESS:
            return {
                loading: false,
                userAddress: payload,
            };
        case USER_ADDRESS_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case USER_ADDRESS_RESET:
            return {
                ...state,
                success: false,
            };
        default:
            return state;
    }
}

// User Address Details Reducer
export const addressDetailsReducer = (state = { ...initialState, addressDetails : {} }, { type, payload }) => {
    switch (type) {
        case USER_ADDRESSDETAIL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USER_ADDRESSDETAIL_SUCCESS:
            return {
                loading: false,
                addressDetails : payload,
            };
        case USER_ADDRESSDETAIL_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case USER_ADDRESSDETAIL_RESET:
            return {
                addressDetails : {},
                success: false,
            };
        default:
            return state;
    }
}