import React, { useState } from 'react'
import "./NewProductCategory.css"
import { useSelector } from 'react-redux'
import axios from "axios"

function NewProductCategory({ handleAddCategory }) {
  const [selectedMainCategory, setSelectedMainCategory] = useState("main")
  const [selectedSubCategory1, setSelectedSubCategory1] = useState("")
  const [selectedSubCategory2, setSelectedSubCategory2] = useState("")
  const [subCategory1, setSubCategory1] = useState([])
  const [subCategory2, setSubCategory2] = useState([])
  const { loading, category } = useSelector((state) => state.categoryDetails)
  const hostName = process.env.REACT_APP_HOST
  // console.log(selectedMainCategory);
  // console.log(selectedSubCategory1);
  // console.log(selectedSubCategory2);

  // const getCategory1 = async (id) => {
  //   alert("hello")
  //   const productData = { "parentCategoryID": id }
  //   const { data } = await axios.post(`${hostName}/api/admin/getSubCategories`, productData);
  //   setSubCategory1(data);
  //   console.log(data);
  // }
 
  const getCategory1 = (categoryName) => {
    setSelectedMainCategory(categoryName)
    const filteredCategory = category.find(category => category.name === categoryName)
    setSubCategory1(filteredCategory.subCategories)
    setSubCategory2([])
  }
  
  const getCategory2 = (categoryName) => {
    setSelectedSubCategory1(categoryName)
    const filteredSubCategory = subCategory1.find(category => category.name === categoryName)
    setSubCategory2(filteredSubCategory.subSubCategories)
  }



  return (
    <div className='categoryContainer'>
      
      <div className='topCategory'>
        <label> <strong> Select Main Category </strong> </label>
        <select onChange={(e) => getCategory1(e.target.value)} >
          <option>Select Product Category  </option>
          {!loading && category.map((e) => {
            return <option value={e.name} key={e.id} >{e.name}</option>
          })}
        </select>

      </div>

      <div className='subCategory1'>
        <label><strong>Select Primary Sub-Category </strong> </label>
        <select onChange={(e) => getCategory2(e.target.value)} >
          <option>Select Product Category </option>
          {subCategory1.map((e) => {
            return <option value={e.name} key={e.id} >{e.name}</option>
          })}
        </select>

      </div>

      <div className='subCategory2'>
        <label><strong>Select Secondary Sub-Category </strong> </label>
        <select onChange={(e) => setSelectedSubCategory2(e.target.value)}>
          <option>Select Product Category</option>
          {subCategory2.map((e) => {
             return <option value={e.name} key={e.id} >{e.name}</option>
          })}
        </select>

      </div>

      <button className='btn-addCategory' onClick={() => handleAddCategory(`${selectedMainCategory},${selectedSubCategory1},${selectedSubCategory2}`)}>Next</button>
      {/* <button className='btn-addCategory' onClick={sendCategoryString}>Next</button> */}


    </div>
  )
}

export default NewProductCategory