import React, { useEffect } from 'react'
import Footer from '../../Component/Footer/Footer'
import Header from '../../Component/Header/Header'
import "./Checkout.css"
import { useDispatch, useSelector } from 'react-redux'
import Subtotal from '../../Component/Cart/Subtotal'
import CheckoutItemCart from '../../Component/Checkout/CheckoutItemCard'
import CheckoutAddressCard from '../../Component/Checkout/CheckoutAddressCard'
import { getShippingAddress } from '../../actions/userAction'

function Checkout() {
  const { product, loading } = useSelector((state) => state.productDetails)
  const dispatch =useDispatch();

  useEffect(()=>{
    dispatch(getShippingAddress())
  },[])

  return (
    <>
      <Header />
      <div className='checkoutPageContainer'>
        <div className='checkoutLeftContainer'>
       
          <div className='productDesc'>
            <CheckoutItemCart productName={product.productName} color={product.productColor} listingPrice={product.productListingPrice}
              imagePath={product.productImg[0]} />
          </div>
        
          <div className='selectAddress'>
            <CheckoutAddressCard />
          </div>
          
          <div className='selectAddress'>
          <p className='checkoutHeading'>Payment</p>
          <p className='mx-2'><span>Payment Mode : </span> <span> ONLINE </span></p>
          </div>
        </div>
       
        <div className='checkoutRightContainer'>
          <Subtotal listingPrice={product.productListingPrice} MRP = {product.productMRP} />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Checkout