import React from 'react'
import "./SliderProductCard.css"
import { Link } from 'react-router-dom'

function SliderProductCard({ name, image, price , productID }) {
    const reactHostName = process.env.REACT_APP_HOST;

    const handleCardClick=()=>{
        window.open(`${reactHostName}/product/${name}/${productID}`)
    }

    return ( 
      <div className="slider-product-card" onClick={handleCardClick}>
            <img src={image} alt={name} />
            <div className="product-details">
                <h3>{name}</h3>
                <div className="price">{price}</div>
            </div>
        </div>
    )
}

export default SliderProductCard