import React from 'react'
import { useSelector } from 'react-redux'

function CheckoutAddressCard() {
    const { loading, userAddress } = useSelector((state) => state.address)

  return (
    // <div className='addressContainer'>
    <>
    <p className='checkoutHeading'>Shipping Address</p>
       {userAddress.map((e) =>
                        <div className='addressContainer my-3'>
                            <div className="addressLeft ">
                                <p>
                                    <span className='fw-bold'>{e.firstName} {e.lastName} 
                                    </span> &nbsp; &nbsp; &nbsp;<span className='fw-bold'>{e.phone}</span>
                                    </p>
                                <p>{e.address} , {e.city} , {e.state} </p>
                                <p className='fw-bold'> {e.zip}</p>
                            </div>
                        </div>

                    )}
                    </>
    // </div>
  )
}

export default CheckoutAddressCard
