import React from 'react';
import Slider from "react-slick"
import { bannerData } from "../../Data/data"
import "./DealSlider.css"

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button className={className} style={{ ...style, background: "white", width: "35px", height: "36px" }} onClick={onClick} />
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button className={className} style={{ ...style, background: "white", width: "35px", height: "36px" }} onClick={onClick} />
  );
}

function DealSlider(props) {
  var settings = {
    // autoplay: true,
    // autoplaySpeed: 4000,
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    // fade: true
  };

  return (
    <Slider {...settings}>
      {bannerData.map((data, id) => {
        return <div>
          <img className={props.class} src={data.url} />
        </div>
      })
      }
    </Slider>

  )
}

export default DealSlider