import React, { useEffect } from 'react'
import Header from "../../Component/Header/Header"
import Footer from "../../Component/Footer/Footer"
import CartItemCard from "../../Component/Cart/CartItemCard"
import "./Cart.css"
import Subtotal from '../../Component/Cart/Subtotal'
import { useDispatch, useSelector } from 'react-redux'
import { getUserCartProducts } from '../../actions/cartAction'

function Cart() {
  const dispatch = useDispatch()

  const { cartProducts, loading } = useSelector((state) => state.cart)
  // console.log(cartProducts);
 
  const getcartProducts =()=>{
    dispatch(getUserCartProducts())
  }

  useEffect(() => {
   getcartProducts()
  }, [])

  return (
    <>
      <Header />
      <div className='cart'>
        <div className='cartList'>
          <div className='cartHead'>
            <span id="cartHeading">Shopping Cart </span>
            <span id="cartItemPrice">Price</span>
          </div>
          {cartProducts.map((e) => {
            return <CartItemCard productName = {e.productName} imagePath = {e.productImg[0]} listingPrice = {e.productListingPrice} MRP = {e.productMRP} color = {e.productColor} quantity = {e.cartProductQuantity} getcartProducts={getcartProducts} productID = {e._id}/>
          })}

        </div>
        <div className='cartTotal'></div>
        <Subtotal />
      </div>
      <div className='cartFooter'>
        <Footer />
      </div>
    </>
  )
}

export default Cart