import React, { useEffect, useState } from 'react'
import axios from "axios";
import "./QuestionAnswer.css"
import OvalLoader from "../../Loader/OvalLoader"


function QuestionAnswer({ productId, sellerID }) {
    {
        const [question, setQuestion] = useState('');
        const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
        const [showQuestionForm, setShowQuestionForm] = useState(false);
        const [loading , setLoading] = useState(false)
        const hostName = process.env.REACT_APP_API_HOST;
        const token = localStorage.getItem("eToken")
    

        const getProductQuestions = async()=>{
            setLoading(true)

            var myHeaders = new Headers();
            myHeaders.append("auth-token", token );
            
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };
            
           const data = await fetch(`${hostName}/api/Product/getQuestions/${productId}`, requestOptions)
           const questionAnswer = await data.json()
           setQuestionsAndAnswers(questionAnswer)
           setLoading(false)
        }

        useEffect(() => {
                getProductQuestions()  
        }, [productId])


        const handleAskQuestion = () => {
            setShowQuestionForm(true);
        };

        const closeTextArea = () => {
            setShowQuestionForm(false);
            setQuestion('');
        };

        const handleQuestionChange = (e) => {
            setQuestion(e.target.value);
        };

        const handleQuestionSubmit = async () => {
            try {
                const url = `${hostName}/api/Product/addQuestion`
                const body = {
                    sellerID: sellerID,
                    Question: question,
                    productID: productId
                }
                const config = { headers: { "auth-token": token } };

                const response = await axios.post(url, body, config);
                console.log(response.data);

                if (response.status === 200) {
                    setQuestion('');
                    setShowQuestionForm(false);
                } else {
                    console.error('Failed to submit question:', response.statusText);
                }

            } catch (error) {
                console.error('Error submitting question:', error);
            }
            getProductQuestions()
        };

        return (
            <>
                <div className='questionHeader'>
                    <h2>Questions About Product ? </h2>
                    {showQuestionForm ? <button onClick={closeTextArea}>Close <strong>X</strong></button> : <button onClick={handleAskQuestion}>Ask a Question</button>}
                </div>
                {showQuestionForm && (
                    <div>
                        <textarea
                            placeholder="Type your question here..."
                            value={question}
                            onChange={handleQuestionChange}
                        />
                        <div style={{ textAlign: "right" }} ><button className='add-to-cart' style={{ padding: "0.3rem 3%" }} onClick={handleQuestionSubmit}>Submit Question</button></div>
                    </div>
                )}

                { loading &&<div className='qnaBox'> <OvalLoader/> </div>}

             { !loading&&<div className='qnaBox'>
                    {questionsAndAnswers.length === 0 ? (
                        null
                    ) : (
                        <ul>
                            {questionsAndAnswers.map((qa, index) => (
                                <li key={index}>
                                   <span style={{color:"#982E53"}}> <strong>Q:</strong> {qa.Question} </span>
                                    <br />
                                    {qa.answer ? 
    
                                           <> <strong>A:</strong> {qa.answer}</>
                                         : <> <strong>A:</strong> NA </>
                                         
                                    }
                                </li>
                            ))}
                        </ul>
                    )}
                </div>}
            </>
        );
    };
}

export default QuestionAnswer