import React from 'react'
import Register from '../../Component/Register&Auth/Register'
import "../Signup/Signup.css"

function Signup() {
    return (
        <>
            <Register />
        </>
    )
}

export default Signup