import React from 'react'

function UserProducts(props) {
  const hostName = process.env.REACT_APP_API_HOST;
  return (
    <>
      <div className='cartItems'>
        <img style={{ padding: "0.5rem" }} src={`${hostName}/${props.imagePath}`} alt="Product Image"></img>

        <div className='itemDetails'>
          <p className="fw-bold" id="cartProductName">{props.productName}</p>
          <p><span className='fw-bold' >Color : </span> <span id="Color">{props.color}</span></p>
        </div>

        <div className='priceContainer'>
          <p id="MRP"><i class="uil uil-dollar-alt"></i>{props.MRP}</p>
          <p id="ListingPrice"><i class="uil uil-dollar-alt"></i>{props.listingPrice}</p>
        </div>

        <div>
          <span><strong>Added Date :</strong> {props.createdAt}</span>
        </div>

      </div>


    </>
  )
}

export default UserProducts