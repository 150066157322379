import React from 'react'
import ProductCard from "../ProductCard/ProductCard"
import "./ProductCarousel.css"
import Slider from "react-slick"
import "./Slick.css"
import "./Slick-theme.css"
import SliderProductCard from './SliderProductCard'

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, background: "white", width: "35px", height: "36px" }}
      onClick={onClick} />
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, background: "white", width: "35px", height: "36px" }}
      onClick={onClick} />
  );
}

function ProductCarousel(props) {
  const { slidesToShow, slidesToScroll, Data } = props;
  const hostName = process.env.REACT_APP_API_HOST
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  };
console.log(Data);
  return (
    <>
      <Slider {...settings}>
        {Data.map((e) => { return e.productImg ? <div><SliderProductCard image={`${hostName}/${e.productImg[0]}`} price={e.productListingPrice} productID={e._id} name={e.productName} key={e._id} /></div> : 
       <div>
        <SliderProductCard image={e.url} price="400" name={e.title.shortTitle} productID={e._id} key={e._id} />
        </div>
       })}
      </Slider>
    </>
  )
}

export default ProductCarousel